import React, { useEffect, useState } from 'react';
import { connect, useIntl } from 'umi';
import { Spin, Button, message } from 'antd';

import { GetTrialPlan } from 'services/trialPlan';

import StartTrialModal from './StartTrialModal';
import TrialSessionContent from './TrialSessionContent';

import styles from '../detail.less';

const TrialSession = ({ subjectDetail }) => {
  const intl = useIntl();

  const { subjectInfo } = subjectDetail || {};
  const { id: userId } = subjectInfo || {};

  const [trialData, setTrialData] = useState({});
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const initData = async (callback) => {
    setLoading(true);

    const res = await GetTrialPlan({ userId });

    if (res.response.ok) {
      setTrialData(res.data || {});

      callback && callback();
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [userId]);

  const onStartTrial = () => {
    setVisible(true);
  };

  const EmptyCom = (
    <div className={styles.trialPlan}>
      <div className={styles.trialPlanText}>
        {intl.formatMessage({ id: 'Child has not yet started' })}
      </div>
      <Button type="primary" onClick={onStartTrial}>
        {intl.formatMessage({ id: 'trialStart' })}
      </Button>
    </div>
  );

  const modalProps = {
    userId,
    visible,
    onReload: initData,
    onCloseModal: () => setVisible(false),
  };

  const contentProps = {
    userId,
    trialData,
    subjectInfo,
    onReload: initData,
  };

  if (!Object.keys(trialData).length && loading) {
    return (
      <div
        style={{
          height: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div className={styles.tabContent}>
      {Object.keys(trialData).length > 0 ? (
        <TrialSessionContent {...contentProps} />
      ) : (
        EmptyCom
      )}

      {visible && <StartTrialModal {...modalProps} />}
    </div>
  );
};

export default connect(({ user, subjectDetail }) => ({
  subjectDetail,
}))(TrialSession);
