import React, { useState, useEffect } from 'react';

import { useIntl } from 'umi';
import { Modal, message, Spin, Button, Tree } from 'antd';

import { GetPrograms, PostPrograms } from 'services/trialPlan';

const AddProgramModal = ({
  userId,
  visible,
  currentItem,
  trialData,
  onCloseModal,
  onReload,
  findNextCategoryId,
}) => {
  const intl = useIntl();

  const { id: trialPlanId, items = [] } = trialData || {};
  const { category: currentCategory } = currentItem || {};
  const { id: currentCategoryId } = currentCategory || {};

  const [loading, setLoading] = useState({
    initLoading: false,
    submitLoading: false,
  });
  const [programsData, setProgramsData] = useState({});
  const [checkedKeys, setCheckedKeys] = useState([]);

  const {
    trainingPrograms = [],
    category,
    trialPlanItems = [],
  } = programsData || {};
  const { displayName = '', id: categoryId } = category || {};

  const initData = async (nextKey) => {
    setLoading({
      ...loading,
      initLoading: true,
    });
    const res = await GetPrograms({
      categoryId: nextKey || currentCategoryId,
      trialPlanId,
    });

    if (res.response.ok) {
      setProgramsData(res?.data || {});
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setLoading({
      ...loading,
      initLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, [currentCategoryId]);

  useEffect(() => {
    if (trialPlanItems.length) {
      setCheckedKeys(
        trialPlanItems
          .map((item) => {
            const { trainingProgram } = item || {};
            const { difficulty2, id } = trainingProgram || {};
            return [id, difficulty2];
          })
          .flat(),
      );
    }
  }, [trialPlanItems]);

  const handleCancel = () => {
    onCloseModal();
  };

  const handleOk = async (type) => {
    const programIds = checkedKeys.filter((item) => !item.includes('lv'));

    if (programIds.length === 0) {
      message.error(
        intl.formatMessage({ id: 'Please select at least one program' }),
      );
      return;
    }
    setLoading({
      ...loading,
      submitLoading: true,
    });

    const params = {
      categoryId,
      trialPlanId,
      programIds,
    };

    const res = await PostPrograms(params);

    if (res.response.ok) {
      setCheckedKeys([]);

      if (type === 'submit') {
        onCloseModal();
      } else {
        const nextKey = findNextCategoryId(categoryId, items);
        if (nextKey) {
          initData(nextKey);
        } else {
          // 未找到未配置的项目
          onCloseModal();
        }
      }
      onReload();
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setLoading({
      ...loading,
      submitLoading: false,
    });
  };

  const treeData = trainingPrograms.map((item) => {
    const { difficulty, trainingPrograms = [] } = item || {};

    return {
      title: difficulty,
      key: difficulty,
      children: trainingPrograms.map((program) => {
        const { title, id } = program || {};
        const { doneTime } =
          trialPlanItems.find((item) => item.trainingProgramId === id) || {};

        return {
          title,
          key: id,
          disabled: doneTime,
          selectable: false,
        };
      }),
    };
  });

  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'Add Programs Title' }, { displayName })}
      open={visible}
      onCancel={handleCancel}
      width={600}
      footer={[
        <Button
          key="back"
          loading={loading.submitLoading}
          onClick={() => handleOk('submitNext')}
        >
          {intl.formatMessage({ id: 'Submit Next' })}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading.submitLoading}
          onClick={() => handleOk('submit')}
        >
          {intl.formatMessage({ id: 'submitOther' })}
        </Button>,
      ]}
    >
      <Spin spinning={loading.initLoading}>
        <h3>{intl.formatMessage({ id: 'Select difficulty level' })}</h3>
        <p />
        {Boolean(trainingPrograms.length) && (
          <Tree
            checkable
            defaultExpandAll
            onCheck={onCheck}
            treeData={treeData}
            checkedKeys={checkedKeys}
          />
        )}
      </Spin>
    </Modal>
  );
};

export default AddProgramModal;
