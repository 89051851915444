import React, { useEffect, useState } from 'react';
import {
  Modal,
  Spin,
  message,
  Radio,
  Space,
  InputNumber,
  Form,
  Button,
} from 'antd';
import { useIntl, getLocale, connect } from 'umi';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { GetBeforeExtendClass } from 'services/rocketService/UserOfflinePlan';
import { GetLastCustomPlan } from 'services/rocketService/Assessment';
import { getAdolescentRecommendProject } from 'cognitiveleap-core-us/report/utils';
import PlannedProgram from 'components/PlannedProgram/PlannedProgram';
import AssessmentQrCode from 'components/AssessmentQrCode';
import config from 'utils/config';
import {
  getToken,
  getTenantId,
  getUserTenantId,
} from 'cognitiveleap-core-us/utils/auth';

import { SHOWTYPE } from './models/subjectDetail';

const { mobileUrl } = config;

import moment from 'moment';

const LangOptions = {
  'en-US': 'en',
  'zh-CN': 'zh-Hans',
  'es-ES': 'es-ES',
};

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const regex = /\([^)]*?(?:课时|sessions)[^)]*?\)/g; // 匹配括号内包含课时或sessions的内容

const ExtendClassModal = ({
  visible,
  currentClassItem,
  subjectInfo,
  confirmLoading,
  handleOk,
  handleCancel,
  subjectAssessment,
  currentUser,
  dispatch,
  modelLoading,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const language = LangOptions[getLocale()];

  const { userOfflineTrainingPlan } = currentClassItem || {};
  const { assessmentQrData, assessmentQrUrlModalVisible, assessmentQrUrl } =
    subjectAssessment;
  const { name } = subjectInfo || {};
  const {
    id: userOfflineTrainingPlanId,
    displayName,
    assessmentStatus = [],
  } = userOfflineTrainingPlan || {};

  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const [extendData, setExtendData] = useState({});
  const [assessmentData, setAssessmentData] = useState({});
  const [loading, setLoading] = useState({
    modalLoading: false,
    customLoading: false,
    againBtnLoading: false,
  });

  const hasPreAssessment = Boolean(assessmentStatus.length);

  const { currentILs = {}, newILs = {} } = extendData || {};
  const {
    arrangeableClass: currArrangeableClass,
    courseCode: currCourseCode,
    totalClass: currTotalClass,
  } = currentILs || {};
  const {
    arrangeableClass: newArrangeableClass,
    courseCode: newCourseCode,
    totalClass: newTotalClass,
  } = newILs || {};

  const {
    arrangeableClass,
    recommendMusicCourse,
    recommendUserAssessment,
    creationTime,
  } = assessmentData || {};
  const { userAssessment } = recommendUserAssessment || {};
  const { assessment, id: userAssessmentId, finishTime } = userAssessment || {};
  const { title, id: assessmentId } = assessment || {};

  const recommendProject =
    getAdolescentRecommendProject(
      `(${recommendMusicCourse?.toUpperCase()})`,
      language,
    ) || recommendMusicCourse?.toUpperCase();

  const initData = async () => {
    setLoading({
      ...loading,
      modalLoading: true,
    });

    const res = await GetBeforeExtendClass({ userOfflineTrainingPlanId });

    if (res.response.ok) {
      setExtendData(res.data);
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setLoading({
      ...loading,
      modalLoading: false,
    });
  };

  const getCustomData = async () => {
    setLoading({
      ...loading,
      customLoading: true,
    });
    const res = await GetLastCustomPlan({
      userOfflineTrainingPlanId,
      fromDatabase: false,
    });

    if (res.response.ok) {
      const { recommendUserAssessment } = res.data || {};
      const { userAssessment } = recommendUserAssessment || {};
      const { needPay, status } = userAssessment || {};
      if (status == 'ReportedReady') {
        // 需要获取报告
        if (needPay) {
          setCurrentStep(-1);
        } else {
          setCurrentStep(0);
        }
      }
      setAssessmentData(res.data);
    }
    setLoading({
      ...loading,
      customLoading: false,
    });
  };

  useEffect(() => {
    if (hasPreAssessment) {
      getCustomData();
    } else {
      // 无前侧，直接续课弹窗
      setCurrentStep(1);
      initData();
    }
  }, []);

  const onFinish = (values) => {
    handleOk({ ...values, userOfflineTrainingPlanId }, currentILs, newILs);
  };

  const showCustomPlanModal = () => {
    Modal.confirm({
      width: 500,
      title: intl.formatMessage(
        { id: 'Add sessions for' },
        { childName: name, trainPlanName: displayName },
      ),
      icon: <ExclamationCircleOutlined />,
      content: (
        <Space direction="vertical">
          <a onClick={() => setOpen(true)}>
            {displayName} {intl.formatMessage({ id: 'Training Plan' })}
          </a>
          <div>
            {intl.formatMessage({ id: 'Generated' })}{' '}
            {moment(creationTime).format('MM-DD-YYYY HH:mm')}
          </div>
        </Space>
      ),
      cancelText: intl.formatMessage({ id: 'previous' }),
      okText: intl.formatMessage({ id: 'next' }),
      onOk() {
        initData();
        setCurrentStep(currentStep + 1);
      },
      onCancel() {},
    });
  };

  const plannedProgramProps = {
    open,
    activePlan: userOfflineTrainingPlan,
    onClose: () => {
      setOpen(false);
    },
  };

  const editAssessment = () => {
    setLoading({
      ...loading,
      againBtnLoading: true,
    });
    delete userAssessment.id;
    dispatch({
      type: 'subjectAssessment/getAssessmentQrCode',
      payload: {
        record: {
          ...userAssessment,
          assessmentName: title,
          assessmentId,
        },
        intl,
        callback: () => {
          handleCancel();
          dispatch({
            type: 'subjectDetail/changeTab',
            payload: SHOWTYPE.RocketAssessment,
          });
        },
        closeLoading: () => {
          setLoading({
            ...loading,
            againBtnLoading: false,
          });
        }
      },
    });
  };

  const qrCodeProps = {
    url: assessmentQrUrl,
    currentUser,
    visible: assessmentQrUrlModalVisible,
    assessmentInfo: assessmentQrData,
    onCancel: () => {
      dispatch({
        type: 'subjectAssessment/updateState',
        payload: {
          assessmentQrUrlModalVisible: false,
        },
      });
    },
    downloadAssessmentQR: () => {
      dispatch({
        type: 'subjectAssessment/downloadAssessmentQRPhoto',
        payload: { intl },
      });
    },
  };

  const getInitMode = () => {
    if (Boolean(currArrangeableClass)) {
      return 'Continue';
    } else {
      if (currCourseCode === newCourseCode) {
        return 'Reset';
      } else {
        return 'New';
      }
    }
  };

  const getFooter = () => {
    if (hasPreAssessment && currentStep === 0) {
      return [
        <Button
          key="back"
          loading={loading.againBtnLoading}
          onClick={editAssessment}
        >
          {intl.formatMessage({ id: 'Test Again' })}
        </Button>,
        <Button key="submit" type="primary" onClick={showCustomPlanModal}>
          {intl.formatMessage({ id: 'Customized Training Plan' })}
        </Button>,
      ];
    } else if (currentStep === -1) {
      return [
        <Button
          key="getReport"
          type="primary"
          loading={
            modelLoading.effects['subjectAssessment/getAssessmentReport'] ||
            loading.customLoading
          }
          onClick={() => {
            dispatch({
              type: 'subjectAssessment/getAssessmentReport',
              payload: {
                record: userAssessment,
                intl,
                customReload: getCustomData,
              },
            });
          }}
        >
          {intl.formatMessage({ id: 'getReport' })}
        </Button>,
      ];
    } else if (currentStep == 1) {
      return [
        <Button
          key="back"
          onClick={() => {
            if (currentStep === 1 && hasPreAssessment) {
              setCurrentStep(currentStep - 1);
            } else {
              handleCancel();
            }
          }}
        >
          {intl.formatMessage({
            id: currentStep === 1 && hasPreAssessment ? 'previous' : 'cancel',
          })}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={modelLoading.effects['subjectOfflinePlan/extendClassOk']}
          onClick={() => form.submit()}
        >
          {intl.formatMessage({ id: 'submit' })}
        </Button>,
      ];
    }
    return null;
  };

  return (
    <>
      <Modal
        title={intl.formatMessage({ id: 'Add Sessions Class' })}
        open={visible}
        width={750}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={getFooter()}
      >
        {currentStep === null && <Spin />}
        {currentStep === -1 && (
          <Spin spinning={loading.modalLoading}>
            <h3>
              {intl.formatMessage(
                { id: 'Add sessions for' },
                { childName: name, trainPlanName: displayName },
              )}
            </h3>
            <p />
            <div>{intl.formatMessage({ id: 'access the report first' })}</div>
            <Space>
              <div>{title}</div>
              <span style={{ color: 'gray' }}>
                {moment(finishTime).format('MM-DD-YYYY HH:mm')}
              </span>
            </Space>
          </Spin>
        )}
        {currentStep === 0 && (
          <Spin spinning={loading.customLoading}>
            <h3>
              {intl.formatMessage(
                { id: 'Add sessions for' },
                { childName: name, trainPlanName: displayName },
              )}
            </h3>
            <p />
            <Space direction="vertical">
              <Space>
                <div>
                  {intl.formatMessage({
                    id: 'Latest Recommended Music Module',
                  })}
                </div>
                <div>
                  {recommendProject?.replace(regex, '').trim()}{' '}
                  {arrangeableClass}
                  {intl.formatMessage({ id: 'session' })}
                </div>
              </Space>
              <Space>
                <a
                  onClick={() => {
                    const reportUrl = `${mobileUrl}/m/rocketReport?to=${userAssessmentId}&t=${getToken()}&h=${getTenantId()}&ts=${getUserTenantId()}`;
                    window.open(reportUrl, '_blank');
                  }}
                >
                  {title}
                </a>
                <span style={{ color: 'gray' }}>
                  {moment(finishTime).format('MM-DD-YYYY HH:mm')}
                </span>
              </Space>
            </Space>
          </Spin>
        )}
        {currentStep === 1 && (
          <Spin spinning={loading.modalLoading}>
            <h3 style={{ marginBottom: '20px' }}>
              {intl.formatMessage(
                { id: 'Add sessions for' },
                { childName: name, trainPlanName: displayName },
              )}
            </h3>

            {Boolean(Object.keys(currentILs).length) && (
              <Form
                form={form}
                name="extendClass"
                {...formItemLayout}
                onFinish={onFinish}
                initialValues={{
                  mode: getInitMode(),
                }}
              >
                <Form.Item
                  name="mode"
                  label={intl.formatMessage({ id: 'Renewal Mode' })}
                >
                  <Radio.Group>
                    <Radio
                      value="Continue"
                      disabled={!Boolean(currArrangeableClass)}
                    >
                      {intl.formatMessage({
                        id: 'Continue Current Music Progress',
                      })}
                    </Radio>
                    {newCourseCode === currCourseCode ? (
                      <Radio value="Reset">
                        {intl.formatMessage({ id: 'Reset Progress' })}
                      </Radio>
                    ) : (
                      <Radio value="New">
                        {intl.formatMessage({ id: 'New Music Module' })}
                      </Radio>
                    )}
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.mode !== currentValues.mode
                  }
                >
                  {() => {
                    let classNum = 0;
                    let label = currCourseCode;
                    const { mode } = form.getFieldsValue(true) || {};
                    switch (mode) {
                      case 'New':
                        label = newCourseCode;
                        classNum = newArrangeableClass;
                        break;
                      case 'Continue':
                        classNum = currArrangeableClass;
                        break;
                      case 'Reset':
                        classNum = currTotalClass;
                        break;
                    }
                    return (
                      <Form.Item
                        label={`${label?.toUpperCase()}${intl.formatMessage({
                          id: 'Available Session Hours',
                        })}`}
                      >
                        <span>
                          {classNum}{' '}
                          {intl.formatMessage({ id: 'Calculated based' })}
                        </span>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.mode !== currentValues.mode
                  }
                >
                  {() => {
                    let maxClassNum = 1;
                    const { mode } = form.getFieldsValue(true) || {};
                    switch (mode) {
                      case 'New':
                        maxClassNum = newArrangeableClass;
                        break;
                      case 'Continue':
                        maxClassNum = currArrangeableClass;
                        break;
                      case 'Reset':
                        maxClassNum = currTotalClass;
                        break;
                    }
                    form.setFieldValue('totalNum', maxClassNum);
                    return (
                      <Form.Item
                        name="totalNum"
                        label={intl.formatMessage({ id: 'CurrSessions' })}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <InputNumber min={1} max={maxClassNum} />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Form>
            )}
          </Spin>
        )}
      </Modal>
      {open && <PlannedProgram {...plannedProgramProps} />}
      <AssessmentQrCode {...qrCodeProps} />
    </>
  );
};

export default connect(({ subjectAssessment, user, loading }) => ({
  subjectAssessment,
  currentUser: user.currentUser,
  modelLoading: loading,
}))(ExtendClassModal);
