import React, { useState, useEffect } from 'react';
import { Button, Space, Spin } from 'antd';
import { setLocale, getLocale, useIntl } from 'umi';

import { CenterGetSubjectInfo } from 'services/userManager';
import { GetTrainerDetail } from 'services/trainingSystem';
import { SetLanguage } from 'services/language';
import { GetTrialPlan } from 'services/trialPlan';

import { showAttributeName } from 'utils/feedback';

import veryHappySelect from 'assets/veryHappySelect.png';
import happySelect from 'assets/happySelect.png';
import badSelect from 'assets/badSelect.png';

import styles from './index.less';

import moment from 'moment';

const LangOptions = {
  'en-US': 'en',
  'zh-CN': 'zh-Hans',
  'es-ES': 'es',
};

const showStandardList = {
  Below: badSelect,
  Meeting: happySelect,
  Exceeding: veryHappySelect,
};

const TrialSessionRecord = ({ location }) => {
  const intl = useIntl();

  const {
    subjectId,
    trainerId,
    type = 'download',
    culture,
    CognitiveleapUser,
    userTenantId,
    tenantId,
  } = location.query || {};

  const [showFormData, setShowFormData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [trialData, subjectInfo, trainerInfo] = showFormData;

  const { complaint = {}, items = [], sessionFeedbacks = [] } = trialData || {};

  const { programFeedbackProperty, value = '' } = complaint || {};
  const { displayName } = programFeedbackProperty || {};

  // 总体反馈数据
  const showData = (type, key = 'value') => {
    const findData =
      sessionFeedbacks.find(
        (item) => item.programFeedbackProperty.name === type,
      ) || {};

    const { value, programFeedbackProperty } = findData || {};

    const { displayName } = programFeedbackProperty || {};

    switch (type) {
      case 'courseTime':
        return value ? moment(value).format('MM/DD/YYYY HH:mm') : '';
      case 'overallBehaviour':
        return key === 'value' ? value : displayName;
      default:
        return value;
    }
  };

  const { name: userName } = subjectInfo || {};
  const { name: trainerName } = trainerInfo || {};

  const showComplaintText = showAttributeName('Checkbox', value, complaint);

  const initData = async () => {
    setLoading(true);

    if (getLocale() !== culture) {
      const res = await SetLanguage({ culture: LangOptions[culture] });
      if (res.response.ok) {
        setLocale(culture);
      }
    }

    if (CognitiveleapUser && userTenantId) {
      localStorage.setItem('CognitiveleapUser', CognitiveleapUser);
      localStorage.setItem('userTenantId', userTenantId);
      if (tenantId) localStorage.setItem('tenantId', tenantId);
    }

    const resList = await Promise.all([
      GetTrialPlan({ userId: subjectId }),
      CenterGetSubjectInfo(subjectId),
      GetTrainerDetail({ trainerId }),
    ]);

    if (resList.every((res) => res.response.ok)) {
      setShowFormData(resList.map((item) => item.data));
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  const downloadPdf = () => {
    document.getElementById('downloadButton').style.display = 'none';
    window.print();
    document.getElementById('downloadButton').style.display = 'block';
  };

  return (
    <div className={styles.container}>
      <Spin spinning={loading}>
        <div className={styles.breadboard}>
          <table>
            <thead>
              <tr style={{ height: '60px', fontSize: '20px' }}>
                <th colspan="6">
                  {intl.formatMessage({
                    id: 'HFS Trial Session Feedback Form',
                  })}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="2">
                  {intl.formatMessage({ id: 'Child Name' })}: {userName}
                </td>
                <td colspan="2">
                  {intl.formatMessage({ id: 'Trainer' })}: {trainerName}
                </td>
                <td colspan="2">
                  {intl.formatMessage({ id: 'Training Date' })}:{' '}
                  {showData('courseTime')}
                </td>
              </tr>
              <tr align="left">
                <td colspan="6">
                  {displayName}
                  <p />
                  <div style={{ fontWeight: '400' }}>{showComplaintText}</div>
                </td>
              </tr>
              <tr align="left">
                <td colspan="6">
                  {showData('overallBehaviour', 'displayName')}
                  <p />
                  <div style={{ fontWeight: '400' }}>
                    {showData('overallBehaviour')}
                  </div>
                </td>
              </tr>
              <tr>
                <td align="center">
                  {intl.formatMessage({ id: 'Training Type' })}
                </td>
                <td colspan="2">{intl.formatMessage({ id: 'Program' })}</td>
                <td colspan="2">
                  {intl.formatMessage({ id: 'Training Goals' })}
                </td>
                <td align="center">
                  {intl.formatMessage({ id: 'Program Completion Status' })}
                </td>
              </tr>
              {items
                .filter((item) => item.trialPlanItems.length > 0)
                .map((item) => {
                  let firstItem;
                  const { category, trialPlanItems = [] } = item || {};
                  const { displayName, id } = category || {};

                  if (trialPlanItems.length > 0) {
                    firstItem = trialPlanItems[0];
                  }

                  const { standard: firstStandard, trainingProgram } =
                    firstItem || {};
                  const {
                    title: firstStandardTitle,
                    difficulty2: firstStandardDifficulty,
                    targets: firstTargets,
                  } = trainingProgram || {};

                  return (
                    <>
                      <tr key={id}>
                        <th scope="row" rowspan={trialPlanItems.length}>
                          {displayName}
                        </th>
                        {firstItem && (
                          <>
                            <td colspan="2" style={{ fontWeight: '400' }}>
                              {firstStandardTitle} - {firstStandardDifficulty}
                            </td>
                            <td
                              colspan="2"
                              style={{
                                maxWidth: '420px',
                                fontWeight: '400',
                                'word-wrap': 'break-word',
                                'word-break': 'break-all',
                              }}
                            >
                              {firstTargets
                                .map((target) => target.displayName)
                                .join(', ')}
                            </td>
                            <td align="center">
                              {showStandardList[firstStandard] && (
                                <img src={showStandardList[firstStandard]} />
                              )}
                            </td>
                          </>
                        )}
                      </tr>
                      {trialPlanItems.length > 1 &&
                        trialPlanItems.slice(1).map((planItem) => {
                          const { trainingProgram, id, standard } =
                            planItem || {};
                          const { title, difficulty2, targets } =
                            trainingProgram || {};
                          return (
                            <tr key={id}>
                              <td colspan="2" style={{ fontWeight: '400' }}>
                                {title} - {difficulty2}
                              </td>
                              <td
                                colspan="2"
                                style={{
                                  fontWeight: '400',
                                  maxWidth: '420px',
                                  'word-wrap': 'break-word',
                                  'word-break': 'break-all',
                                }}
                              >
                                {targets
                                  .map((target) => target.displayName)
                                  .join(', ')}
                              </td>
                              <td align="center">
                                {showStandardList[standard] && (
                                  <img src={showStandardList[standard]} />
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  );
                })}
            </tbody>
            <tfoot align="left">
              <tr>
                <th colspan="6">
                  {intl.formatMessage({ id: 'Note' })}:
                  <p />
                  <Space size="large" style={{ fontWeight: '400' }}>
                    <Space>
                      <img src={badSelect} />
                      <div>{intl.formatMessage({ id: 'Below Standard' })}</div>
                    </Space>
                    <Space>
                      <img src={happySelect} />
                      <div>
                        {intl.formatMessage({ id: 'Meeting Standard' })}
                      </div>
                    </Space>
                    <Space>
                      <img src={veryHappySelect} />
                      <div>
                        {intl.formatMessage({ id: 'Exceeding Standard' })}
                      </div>
                    </Space>
                  </Space>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </Spin>

      {type !== 'download' && (
        <div className={styles.download}>
          <Button onClick={downloadPdf} id="downloadButton">
            {intl.formatMessage({ id: 'printTimetable' })}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TrialSessionRecord;
