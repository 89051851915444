import React, { useRef, useEffect, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { Drawer, Space, Spin } from 'antd';
import { useIntl, getLocale } from 'umi';
import { DownloadOutlined } from '@ant-design/icons';

import moment from 'moment';

import { GetLastCustomPlan } from 'services/rocketService/Assessment';
import { countAge } from 'utils/utils';
import { getAdolescentRecommendProject } from 'cognitiveleap-core-us/report/utils';

import RoseChart from './RoseChart';
import SportItemList from './SportItemList';

import styles from './index.less';

const LangOptions = {
  'en-US': 'en',
  'zh-CN': 'zh-Hans',
  'es-ES': 'es-ES',
};

const regex = /\([^)]*?(?:课时|sessions)[^)]*?\)/g; // 匹配括号内包含课时或sessions的内容

const PlannedProgram = ({ open, activePlan, onClose }) => {
  const intl = useIntl();

  const language = LangOptions[getLocale()];

  const componentRef = useRef(null);

  const { id, customizedPlanId } = activePlan || {};

  const [loading, setLoading] = useState(false);
  const [assessmentData, setAssessmentData] = useState({});

  const {
    creationTime,
    recommendUserAssessment,
    arrangeableClass,
    recommendMusicCourse,
    offlineTrainingPlan,
  } = assessmentData || {};
  const {
    categories = [],
    extraProperties,
    userAssessment,
    recommendDifficulty,
  } = recommendUserAssessment || {};
  const { displayName } = offlineTrainingPlan || {};
  const { reportData: _reportData } = extraProperties || {};
  const { userInfo, assessment } = userAssessment || {};
  const { reportData } = _reportData || {};

  const { recommendProject } = reportData || {};

  const showRecommendProject = getAdolescentRecommendProject(
    recommendProject,
    language,
  );

  const { birthday, name } = userInfo || {};
  const pdfTitle = intl.formatMessage({ id: 'HFS Training Plan' }) + '_' + name;

  const initData = async () => {
    setLoading(true);
    const params = customizedPlanId
      ? { customizedPlanId }
      : {
          userOfflineTrainingPlanId: id,
        };
    const res = await GetLastCustomPlan(params);

    if (res.response.ok) {
      setAssessmentData(res.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [id, customizedPlanId]);

  const getTitleTag = (title, color, bgColor) => {
    return (
      <div className={styles.programTitleTag} style={{ color }}>
        <div className={styles.leftTag} style={{ backgroundColor: bgColor }}>
          [
        </div>
        <div className={styles.tagText}>{title}</div>
        <div className={styles.rightTag}>]</div>
      </div>
    );
  };

  const getMusicTargetText = () => {
    let showId = '';
    switch (recommendMusicCourse) {
      case 'c+sm':
        showId = 'CSMDes';
        break;
      case 'sm':
        showId = 'SMDes';
        break;
      case 'ca':
        showId = 'CADes';
        break;
      case 'rap':
        showId = 'RAPDes';
        break;
      case 'hcf1+hcf2':
        showId = 'HCFDes';
        break;
      default:
        showId = 'CSMDes';
        break;
    }

    return intl.formatMessage({ id: showId });
  };

  return (
    <Drawer
      title={displayName}
      width={855}
      onClose={onClose}
      open={open}
      extra={
        <ReactToPrint
          documentTitle={pdfTitle}
          trigger={() => <DownloadOutlined style={{ fontSize: '18px' }} />}
          content={() => componentRef.current}
        />
      }
    >
      {loading || Object.keys(assessmentData).length == 0 ? (
        <Spin spinning={true} />
      ) : (
        <div ref={componentRef} className={styles.programContent}>
          <div className={styles.programTop}>
            <div className={styles.programTitle}>
              {displayName} {intl.formatMessage({ id: 'Training Plan' })}
            </div>
            <div className={styles.birthday}>
              {moment(creationTime).local().format('MM/DD/YYYY HH:mm')}
            </div>
            <div className={styles.age}>
              {name} {countAge(birthday, intl)}
            </div>
          </div>
          <div className={styles.programPlanList}>
            <div className={styles.classPlan}>
              {getTitleTag(
                intl.formatMessage({ id: 'Session Plan' }),
                '#F96B4B',
                '#FFF1F1',
              )}
              <div className={styles.classPlanContent}>
                <Space align="start" size={5}>
                  <div className={styles.planTitle}>
                    {intl.formatMessage({ id: 'Recommended Session Hours' })}：
                  </div>
                  <div className={styles.planClassNum}>{arrangeableClass}</div>
                </Space>
                <Space align="start" size={5}>
                  <div className={styles.planTitle}>
                    {intl.formatMessage({ id: 'Training Frequency' })}：
                  </div>
                  <div>{intl.formatMessage({ id: 'sessions per week' })}</div>
                </Space>
                <Space align="start" size={5}>
                  <div className={styles.planTitle}>
                    {intl.formatMessage({ id: 'Session Duration' })}：
                  </div>
                  <div>{intl.formatMessage({ id: 'one Hour' })}</div>
                </Space>
              </div>
            </div>

            <div className={styles.musicPlan}>
              {getTitleTag(
                intl.formatMessage({ id: 'Music Plan' }),
                '#55B3B7',
                '#E6F4F4',
              )}
              <div className={styles.musicPlanContent}>
                <Space align="start" size={5}>
                  <div className={styles.planTitle}>
                    {intl.formatMessage({ id: 'Music Module' })}：
                  </div>
                  <div>{showRecommendProject?.replace(regex, '').trim()}</div>
                </Space>
                <Space align="start" size={5}>
                  <div className={styles.planTitle}>
                    {intl.formatMessage({ id: 'Music Target' })}：
                  </div>
                  <div className={styles.planContentText}>
                    {getMusicTargetText()}
                  </div>
                </Space>
              </div>
            </div>

            <div className={styles.sportPlan}>
              {getTitleTag(
                intl.formatMessage({ id: 'Motion Plan' }),
                '#55B3B7',
                '#E6F4F4',
              )}
              <SportItemList
                recommendDifficulty={recommendDifficulty}
                reportData={reportData}
              />
            </div>

            <div className={styles.typePlan}>
              {getTitleTag(
                intl.formatMessage({
                  id: 'Distribution of Program Categories',
                }),
                '#55B3B7',
                '#E6F4F4',
              )}
              <div className={styles.typePlanContent}>
                <RoseChart categories={categories} />
              </div>

              <div className={styles.typePlanDes}>
                {intl.formatMessage({
                  id: 'Training Focus Fundamental Abilities',
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default PlannedProgram;
