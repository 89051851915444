import React, { useEffect } from 'react'
import { connect, useIntl, history } from 'umi'
import { Card, Input, DatePicker, Button, message } from 'antd'
import { getFormatDate, getAssessmentStatus, genderLocale, downloadQr } from 'utils/utils'
import AssessmentSelectModal from 'components/SelectModal'
import moment from 'moment'
import styles from './index.less'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import SendToGuardian from './SendToGuardian'
import { GetDownloadPdf } from 'services/puppeteer'
import { getToken, getTenantId, getUserTenantId } from 'cognitiveleap-core-us/utils/auth'
import config from 'utils/config'

const { mobileUrl } = config
const { Search } = Input;
const { RangePicker } = DatePicker

const dateFormat = 'YYYY-MM-DD';

const QuestionnaireList = ({ questionnaireList, dispatch, loading, currentUser }) => {

    const intl = useIntl()

    useEffect(() => {
        dispatch({ type: 'questionnaireList/loadPage' })
    }, [])

    const { auth, changedTenant, current, userAllTenant, staff } = currentUser || {}
    const { grantedPolicies } = auth
    const { id: currentId } = current || {}
    const roles = currentUser.current ? currentUser.current.roles : []
    const { name } = staff || {}
    const currentInfo = userAllTenant.find(item => item.id === currentId)
    const hasWritePermission = grantedPolicies['RocketSystem.UserAssessment.Create'] === true
    const hasReadPermission = grantedPolicies['RocketSystem.UserAssessment'] === true
    const lookSubjectDetailPermisson = grantedPolicies['RocketSystem.UserOfflinePlan'] === true

    const {
        childName,
        filterDate,
        pagination,
        filters,
        orderBy,
        listData,
        sendToGuardianModalVisible,
        selectAssessmentModalVisible,
        transferList,
        assessmentName,
        btnLoading
    } = questionnaireList

    const listSupport = {
        creationTime: {
            showText: intl.formatMessage({ id: 'creationTime' }),
            showType: 'Time',
            addtional: {
                format: 'YYYY/MM/DD HH:mm',
            },
            sorter: {
                hasSorter: true
            }
        },
        assessmentName: {
            showText: intl.formatMessage({ id: 'testName' }),
            showType: 'Text',
        },
        childName: {
            showText: intl.formatMessage({ id: 'subject' }),
            showType: 'Text',
            render: (text, data) => {
                const { userId } = data
                return (
                    <React.Fragment>
                        {
                            lookSubjectDetailPermisson ?
                                <a
                                    onClick={() => history.push({
                                        pathname: '/system/center/detail/subjectDetail',
                                        query: {
                                            id: userId
                                        }
                                    })}>
                                    {text}
                                </a>
                                :
                                <span>{text}</span>
                        }
                    </React.Fragment>

                )
            }
        },
        gender: {
            showText: intl.formatMessage({ id: 'gender' }),
            showType: 'Text',
        },
        school: {
            showText: intl.formatMessage({ id: 'school' }),
            showType: 'Text',
        },
        age: {
            showText: intl.formatMessage({ id: 'age' }),
            showType: 'Text',
        },
        // guardianName: {
        //     showText: intl.formatMessage({ id: 'guardian' }),
        //     showType: 'Text',
        //     render: (text, data) => {
        //         const { userInfo } = data || {}
        //         const { guardianId } = userInfo || {}
        //         return (
        //             <a
        //                 onClick={() => history.push({
        //                     pathname: '/system/center/detail/userDetail',
        //                     query: {
        //                         id: guardianId
        //                     }
        //                 })}>
        //                 {text}
        //             </a>
        //         )
        //     }
        // },
        // phoneNumber: {
        //     showText: intl.formatMessage({ id: 'phone' }),
        //     showType: 'Text',
        // },
        assessmentStatus: {
            showText: intl.formatMessage({ id: 'status' }),
            showType: 'Text',
            filter: {
                hasFilter: true,
                filterOptions: [
                    {
                        text: getAssessmentStatus('None', intl),
                        value: 'None',
                    },
                    {
                        text: getAssessmentStatus('Started', intl),
                        value: 'Started',
                    },
                    {
                        text: getAssessmentStatus('Finished', intl),
                        value: 'Finished',
                    },
                    {
                        text: getAssessmentStatus('ReportedReady', intl),
                        value: 'ReportedReady',
                    },
                    {
                        text: getAssessmentStatus('Closed', intl),
                        value: 'Closed',
                    },
                ],
            }
        },
    }

    const onSearch = () => {
        dispatch({
            type: 'questionnaireList/onSearch'
        })
    }

    const onChangeChildName = (name) => {
        dispatch({
            type: 'questionnaireList/updateState',
            payload: { childName: name }
        })
    }

    const onChangeAssessmentName = (name) => {
        dispatch({
            type: 'questionnaireList/updateState',
            payload: { assessmentName: name }
        })
    }

    const onChangeDate = async (value) => {
        const startTime = value && value[0] ? getFormatDate(value[0], true) : ''
        const endTime = value && value[1] ? getFormatDate(value[1], false) : ''
        await dispatch({
            type: 'questionnaireList/updateState',
            payload: { filterDate: { startTime, endTime } }
        })
        onSearch()
    }

    const clearFilter = () => {
        dispatch({
            type: 'questionnaireList/clearFilter'
        })
    }

    const assessmentActions = [
        {
            showText: intl.formatMessage({ id: 'details' }),
            itemRender(record) {
                const { status, needPay } = record
                return hasReadPermission && status === 'ReportedReady' && !needPay ? (
                    <span key="viewDetail">
                        <a
                            onClick={() => {
                                const url = '/questionnaireView?id=' + record.id
                                window.open(url, '_blank')
                            }}
                        >
                            {intl.formatMessage({ id: 'details' })}
                        </a>
                    </span>
                ) : null
            },
        },
        {
            showText: intl.formatMessage({ id: 'viewReport' }),
            itemRender(record) {
                const { status, needPay } = record
                return hasReadPermission && status === 'ReportedReady' && !needPay ? (
                    <span key="view">
                        <a
                            onClick={() => {
                                const reportUrl = `${mobileUrl}/m/rocketReport?to=${record.id}&t=${getToken()}&h=${getTenantId()}&ts=${getUserTenantId()}`
                                window.open(reportUrl, '_blank')
                            }}
                        >
                            {intl.formatMessage({ id: 'viewReport' })}
                        </a>
                    </span>
                ) : null
            },
        },
        // {
        //     showText: '下载',
        //     itemRender(record) {
        //         const { status } = record
        //         return status === 'ReportedReady' ? (
        //             <span key="downloadPDF">
        //                 <a
        //                     onClick={() => {
        //                         const reportUrl = `${mobileUrl}/m/rocketReport?to=${record.id}&t=${getToken()}&h=${getTenantId()}&needDownload=true`
        //                         window.open(reportUrl, '_blank')
        //                     }}
        //                 >
        //                     下载
        //           </a>
        //             </span>
        //         ) : null
        //     },
        // },
        {
            showText: intl.formatMessage({ id: 'sendToParents' }),
            itemRender(record) {
                const { status, confirmType, needPay } = record
                return hasWritePermission && status === 'ReportedReady' && (confirmType == 'NotConfirm' || confirmType == 'Public') && !needPay ? (
                    <span key="view">
                        {
                            confirmType == 'NotConfirm' &&
                            <a
                                onClick={() => {
                                    dispatch({
                                        type: 'questionnaireList/updateState',
                                        payload: {
                                            sendToGuardianModalVisible: true,
                                            currentSendToGuardianRecord: record
                                        }
                                    })
                                }}
                            >
                                {intl.formatMessage({ id: 'sendToParents' })}
                            </a>
                        }
                        {
                            confirmType == 'Public' && <span style={{ color: 'grey' }}>{intl.formatMessage({ id: 'hasSend' })}</span>
                        }
                    </span>
                ) : null
            }
        },
        {
            showText: intl.formatMessage({ id: 'getReport' }),
            itemRender(record) {
                const { status, needPay } = record
                return hasWritePermission && status === 'ReportedReady' && needPay ? (
                    <span key="view">
                        <a
                            onClick={() => { dispatch({ type: 'questionnaireList/getAssessmentReport', payload: { record, intl } }) }}
                        >
                            {intl.formatMessage({ id: 'getReport' })}
                        </a>
                    </span>
                ) : null
            },
        },
        {
            showText: intl.formatMessage({ id: 'downloadConsentForm' }),
            itemRender(record) {
                const { assessment: { isNeedInformedConsent, title }, tenantId, userAssessmentInformedConsent, userInfo: { name } } = record
                return isNeedInformedConsent && userAssessmentInformedConsent ? (
                    <span key="download">
                        <a
                            onClick={async () => {
                                const hide = message.loading(intl.formatMessage({ id: 'downloading' }) + '...', 0)
                                const origin = window.location.origin
                                const fileName = name + '-' + title + intl.formatMessage({ id: 'consentForm' })
                                const { userAssessmentId: UserAssessmentId } = userAssessmentInformedConsent
                                const CognitiveleapUser = localStorage.getItem('CognitiveleapUser')
                                // 获取来的数据为"sssdfaf", 将双引号去掉
                                const tempData = CognitiveleapUser.substring(1, CognitiveleapUser.length - 1)

                                // 需要打印的页面完整url
                                const url = [origin + `/informedConsent?UserAssessmentId=${UserAssessmentId}&tenantId=${tenantId}&CognitiveleapUser=${tempData}`]
                                await GetDownloadPdf({ urlList: { ...url }, needPadding: true, needPageNum: true }, fileName)
                                setTimeout(hide, 0)
                                // history.push({
                                //     pathname: '/informedConsent',
                                //     query: {
                                //         tenantId,
                                //         UserAssessmentId
                                //     }
                                // })
                            }}
                        >
                            {intl.formatMessage({ id: 'downloadConsentForm' })}
                        </a>
                    </span>
                ) : null
            },
        }
    ]

    const newListData = listData.map(item => {
        const { assessment, userInfo, status } = item
        const { gender, monthAge, phoneNumber, guardianName, school } = userInfo || {}
        const year = parseInt(monthAge / 12)
        const month = parseInt(monthAge % 12)
        const age = year + month == 0 ? `0${intl.formatMessage({ id: 'y' })}` : `${year > 0 ? `${year}${intl.formatMessage({ id: 'y' })}` : ''} ${month > 0 ? `${month}${intl.formatMessage({ id: 'm' })}` : ''}`
        return {
            ...item,
            assessmentName: assessment.title,
            childName: userInfo.name,
            age: monthAge != null ? age : '',
            assessmentStatus: getAssessmentStatus(status, intl),
            gender: genderLocale(gender, intl),
            phoneNumber,
            guardianName,
            school
        }
    })

    const tableProps = {
        loading: loading.effects['questionnaireList/loadPage'] || loading.effects['questionnaireList/getAssessmentReport'],
        listData: newListData,
        listSupport,
        pagination,
        filters,
        onTableChange(pagi, filt = {}, sorter = {}) {
            let data = { pagination, filters }
            if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
            if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
            if (Object.keys(sorter).length !== 0) data = { ...data, sorter: sorter }
            dispatch({ type: 'questionnaireList/changeTable', payload: data })
        },
        scroll: true,
        sorter: {
            columnKey: 'creationTime',
            order: orderBy
        },
        Actions: assessmentActions,
        actionTitle: intl.formatMessage({ id: 'report' })
    }

    const sendToGuardianProps = {
        visible: sendToGuardianModalVisible,
        loading: loading.effects['questionnaireList/sendToGuardian'],
        onCancel: () => {
            dispatch({
                type: 'questionnaireList/updateState',
                payload: {
                    sendToGuardianModalVisible: false
                }
            })
        },
        onOk: () => {
            dispatch({
                type: 'questionnaireList/sendToGuardian',
                payload: {
                    intl
                }
            })
        }
    }

    const selectAssessmentModalProps = {
        defaultSelectKeys: [],
        title: `${intl.formatMessage({ id: 'addEvaluationQrCode' })}`,
        loading: loading.effects['questionnaireList/uploadAssessmentQr'],
        allList: transferList,
        selectModalVisible: selectAssessmentModalVisible,
        onOk(keys) {
            console.log(keys);
            dispatch({ type: 'questionnaireList/updateState', payload: { selectAssessmentModalVisible: false } })
            keys.forEach(item => {
                dispatch({ type: 'questionnaireList/uploadAssessmentQr', payload: { key: item } }).then(res => {
                    const currentAssessment = transferList.filter(listItem => item == listItem.id)
                    const title = currentAssessment[0].title
                    const role = currentInfo && currentInfo.roles && currentInfo.roles[0] && currentInfo.roles[0].name
                    downloadQr({
                        type: 'base64',
                        data: res.data,
                        title,
                        subTitle: intl.formatMessage({ id: 'useWechatSee' }),
                        description: role === "Coach" ? `${changedTenant.displayName}${name ? '_' + name : ''}` : changedTenant.displayName,
                        downloadName: `${changedTenant.displayName}_${title}_二维码_${moment().format('YYYYMMDD')}`,
                        imgType: 'questionnaire'
                    })
                })
            })
        },
        onCancel() {
            dispatch({ type: 'questionnaireList/updateState', payload: { selectAssessmentModalVisible: false } })
        },
    }

    const isEnterAdmin = () => {
        return JSON.stringify(roles) !== '[]' ? roles.some(r => r.name === 'EnterpriseAdmin') : false
    }

    // 财务
    const isFinance = () => {
        return JSON.stringify(roles) !== '[]' ? roles.some(r => r.name === 'Finance') : false
    }

    const exportExcel = () => {
        dispatch({
            type: 'questionnaireList/exportExcel',
            payload: {
                currentUser
            }
        })
    }

    return (
        <Card
            title={<h1 className={styles.pageName}>{intl.formatMessage({ id: 'questionnaireEvaluationList' })}</h1>}
            bordered={false}
            className={styles.questionnaireList}
        >
            <div className={styles.questionnaireListSearch}>
                <div className={styles.questionnaireListSearchLeft}>
                    <Search
                        placeholder={`${intl.formatMessage({ id: 'subjectName' })}/${intl.formatMessage({ id: 'school' })}`}
                        value={childName}
                        onChange={e => onChangeChildName(e.target.value)}
                        onSearch={value => onSearch(value)}
                        style={{ height: 32, maxWidth: 220 }}
                        className={styles.questionnaireListSearchItem}
                    />
                    <Search
                        placeholder={intl.formatMessage({ id: 'testName' })}
                        value={assessmentName}
                        onChange={e => onChangeAssessmentName(e.target.value)}
                        onSearch={value => onSearch(value)}
                        style={{ height: 32, maxWidth: 220 }}
                        className={styles.questionnaireListSearchItem}
                    />
                    <RangePicker
                        onChange={date => onChangeDate(date)}
                        placeholder={[intl.formatMessage({ id: 'startDate' }), intl.formatMessage({ id: 'endDate' })]}
                        disabledDate={(current) => {
                            return current && current > moment()
                        }}
                        value={[
                            filterDate.startTime ? moment(filterDate.startTime, dateFormat) : null,
                            filterDate.endTime ? moment(filterDate.endTime, dateFormat) : null
                        ]}
                        className={styles.questionnaireListSearchItem}
                    />
                    <Button onClick={clearFilter}>{intl.formatMessage({ id: 'clear' })}</Button>
                </div>
                {
                    hasReadPermission && !isEnterAdmin() && !isFinance() &&
                    <div>
                        <Button type='primary' onClick={() => {
                            dispatch({
                                type: 'questionnaireList/showAssessmentList'
                            })
                        }}>
                            {intl.formatMessage({ id: 'evaluationQrCode' })}
                        </Button>
                    </div>
                }
                <Button loading={btnLoading} disable={(newListData.length === 0).toString()} onClick={exportExcel}>{intl.formatMessage({ id: 'exportList' })}</Button>
            </div>

            <MagicTable {...tableProps} />
            <SendToGuardian {...sendToGuardianProps} />
            <AssessmentSelectModal {...selectAssessmentModalProps} />
        </Card>
    )
}

export default connect(({ questionnaireList, loading, user }) => ({ questionnaireList, loading, currentUser: user.currentUser }))(QuestionnaireList)